import { FunctionComponent } from "react";
import styled from "styled-components";

const B = styled.b`
  position: relative;
  line-height: 150%;
`;
const Div = styled.div`
  position: relative;
  line-height: 150%;
`;
const BusinessRegistrationNumberF = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0px 10px;
`;
const Div1 = styled.div`
//   flex: 1;
  position: relative;
//   display: flex;
//   flex-direction: row;
  line-height: 150%;
  display: inline-block;
  min-width: 147px;
`;
const CompanyContactFrame = styled.div`
  position: relative;
  line-height: 150%;
  @media screen and (max-width: 700px) {
    width: 100%;
    height: 5px;
  }
`;
const ContactDetailsFrame = styled.div`
//   width: 100vw;
  align-self: stretch;
  display: flex;
  flex-direction: row;
//   flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0px 10px;
`;
const RepresentativeContactFrame = styled.div`
//   width: 790px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px 0px;
  max-width: 100%;
  color: var(--g600);
`;
const CopyrightFrame = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--g400);
`;
const CompanyLogoRoot = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-21xl) var(--padding-xl);
  box-sizing: border-box;
  gap: 20px 0px;
  max-width: 100%;
  text-align: left;
  font-size: var(--b4-14-size);
  color: var(--g900);
  font-family: var(--h3-12);
`;

const CompanyLogo: FunctionComponent = () => {
  return (
    <CompanyLogoRoot>
      <B>(주)초록테크</B>
      <RepresentativeContactFrame>
        <BusinessRegistrationNumberF>
          <Div>대표 박태희</Div>
          <Div>|</Div>
          <Div>사업자 등록번호 730 81 01884</Div>
        </BusinessRegistrationNumberF>
        <ContactDetailsFrame>
          <Div1>서울시 경희대로 26 삼의원창업센터 508</Div1>
          <CompanyContactFrame>|</CompanyContactFrame>
          <Div>연락처 070 5222 9836</Div>
          <CompanyContactFrame>|</CompanyContactFrame>
          <Div>팩스 0504 187 2465</Div>
          <CompanyContactFrame>|</CompanyContactFrame>
          <Div1>이메일 choroktech@choroktech.com</Div1>
        </ContactDetailsFrame>
      </RepresentativeContactFrame>
      <CopyrightFrame>
        <Div>Copyright 2024 초록테크 All right reserved.</Div>
      </CopyrightFrame>
    </CompanyLogoRoot>
  );
};

export default CompanyLogo;
