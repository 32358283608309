import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const RedirectPage = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    useEffect(() => {
        localStorage.setItem('hospitalId', params.get('hospitalId')!);
        localStorage.setItem('hospitalName', params.get('hospitalName')!); 
        localStorage.setItem('userId', params.get('userId')!);
    }, []);

    const userAgent = navigator.userAgent || navigator.vendor;

    if (/android/i.test(userAgent )&& location.pathname === '/apps') {
        window.location.href = "https://play.google.com/store/apps/details?id=com.choroktech.client.dr.haru";
    }

    if (/iPad|iPhone|iPod/.test(userAgent)&& location.pathname === '/apps') {
    window.location.href = "https://apps.apple.com/kr/app/id6443821892";
    }

    return <Navigate replace to="/info" />;
}

export default RedirectPage;