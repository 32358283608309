import { FunctionComponent, useRef } from "react";
import styled from "styled-components";


const CamerafillIcon = styled.img`
  height: 24px;
  width: 24px;
  position: relative;
`;
const B = styled.b`
  flex: 1;
  position: relative;
  font-size: var(--b3-16-size);
  line-height: 150%;
  font-family: var(--h2-14);
  color: var(--g800);
  text-align: center;
`;
const CameraRoot = styled.button`
  cursor: pointer;
  border: 1px solid var(--g200);
  padding: var(--padding-21xl) var(--padding-20xl);
  background-color: var(--g0);
  width: 187px;
  height: 104px;
  border-radius: var(--br-xs);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0px 9px;
  white-space: nowrap;
`;

// const ImageUploadButton: FunctionComponent = ({callback}) => {
//   return (
//     <CameraRoot onClick={()=>{callback()}}>
//       <CamerafillIcon alt="" src="/camerafill.svg" />
//       <B>사진 올리기</B>
//     </CameraRoot>
//   );
// };
interface Props {
  callback: (file: File) => void;
}

const ImageUploadButton: FunctionComponent<Props> = ({callback}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: { target: { files: any[]; }; }) => {
    const file = event.target.files[0];
    callback(file);
  };
  const onUploadImage = (e: React.ChangeEvent<HTMLInputElement> | undefined) => {
    if (!e || !e.target.files) {
      return undefined;
    }
    console.log(e.target.files[0].name);
    callback(e.target.files[0]);
  };

  return (
    <>
      <input 
        type="file" 
        style={{ display: 'none' }} 
        ref={fileInputRef} 
        onChange={onUploadImage}
      />
      <CameraRoot onClick={handleButtonClick}>
        <CamerafillIcon alt="" src="/camerafill.svg" />
        <B>사진 올리기</B>
      </CameraRoot>
    </>
  );
};

export default ImageUploadButton;
