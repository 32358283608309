import { FunctionComponent } from "react";
import styled from "styled-components";

const Child = styled.img`
  width: 16px;
  height: 12.8px;
  position: relative;
`;
const Div = styled.div`
  height: 20px;
  width: 20px;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const MingcutecopyFillIcon = styled.img`
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 16px;
`;
const Div1 = styled.div`
  position: relative;
  font-size: var(--font-size-3xs);
  text-decoration: underline;
  line-height: 150%;
  font-family: var(--h2-14);
  color: var(--g600);
  text-align: left;
`;
const TagkeywordRoot = styled.button`
  cursor: pointer;
  border: none;
  padding: var(--padding-9xs) var(--padding-3xs);
  background-color: var(--g0);
  border-radius: var(--br-7xs);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0px 6px;
`;

const CopyButton: FunctionComponent = () => {

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText('info@choroktech.com');
      window.alert('텍스트가 클립보드에 복사되었습니다');
    } catch (err) {
      
    }
  };
  return (
    <TagkeywordRoot onClick={handleCopy}>
      <MingcutecopyFillIcon alt="" src="/mingcutecopyfill.svg" />
      <Div1>복사</Div1>
    </TagkeywordRoot>
  );
};

export default CopyButton;
