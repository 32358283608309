import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    body {
      margin: 0; line-height: normal;
    }
:root {

/* fonts */
--h2-14: Pretendard;

/* font sizes */
--h2-14-size: 14px;
--h3-12-size: 12px;
--font-size-lg: 18px;
--b3-16-size: 16px;
--font-size-3xs: 10px;

/* Colors */
--color-gray: rgba(0, 0, 0, 0.7);
--g0: #fff;
--key-color01: #fda2d3;
--g400: #9fa4a9;
--g600: #63676b;
--g800: #2d2f34;
--g100: #f7f8f9;
--g200: #e9ebed;

/* Gaps */
--gap-xl: 20px;
--gap-0: 0px;
--gap-3xs: 10px;
--gap-47xl: 66px;
--gap-11xl: 30px;
--gap-7xs: 6px;

/* Paddings */
--padding-294xl: 313px;
--padding-xl: 20px;
--padding-base: 16px;
--padding-9xs: 4px;
--padding-5xs: 8px;
--padding-3xs: 10px;
--padding-sm: 14px;
--padding-7xs: 6px;
--padding-11xs: 2px;
--padding-lg: 18px;
--padding-xs: 12px;
--padding-21xl: 40px;
--padding-20xl: 39px;
--padding-4xs: 9px;
--padding-11xl: 30px;
--padding-lgi: 19px;

/* Border radiuses */
--br-xs: 12px;
--br-7xs: 6px;
--br-980xl: 999px;
--br-xl: 20px;

}
`;
