import { FunctionComponent, SetStateAction, useState } from "react";
import styled from "styled-components";
import PhotoImage from "./PhotoImage";
import ImageUploadButton from "./ImageUploadButton";

const B = styled.b`
  align-self: stretch;
  position: relative;
  line-height: 150%;
  white-space: pre-wrap;
`;
const P = styled.p`
  margin: 0;
`;
const Div = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--h3-12-size);
  line-height: 150%;
  color: var(--g400);
`;
const CameraView = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px 0px;
`;
const ImageConfirmationRoot = styled.div`
  align-self: stretch;
  background-color: var(--g0);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-xl) var(--padding-xl);
  gap: 20px 0px;
  text-align: left;
  font-size: var(--h2-14-size);
  color: var(--g800);
  font-family: var(--h2-14);
`;
interface Props {
  callback: (file: File |null) => void;
}
const ImageConfirmation: FunctionComponent<Props> = ({callback}) => {
  
  // const [image, setImage] = useState(null);
  const [image, setImage] = useState<File | null>(null);
  callback(image);
  return (
    <ImageConfirmationRoot>
      <CameraView>
        <B>병원의 정보가 되는 내용을 사진으로 인증해 주세요</B>
        <Div>
          <P>예) 입원 가능한 병원 인증,</P>
          <P>강아지 호텔이 있는 병원 인증의 내용 등</P>
        </Div>
      </CameraView>
      {image===null ? <ImageUploadButton callback={setImage} /> : <PhotoImage image={image} setImage={setImage} />}
      {/* <ImageUploadButton /> */}

      {/* <PhotoImage /> */}
      
    </ImageConfirmationRoot>
  );
};

export default ImageConfirmation;
