import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const ContactPersonFrame = styled.img`
  height: 9.1px;
  width: 10px;
  position: relative;
`;
const VectorFrame = styled.div`
  height: 17px;
  width: 17px;
  border-radius: var(--br-980xl);
  background-color: var(--key-color01);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-11xs);
  box-sizing: border-box;
`;
const Div = styled.div`
  position: relative;
  line-height: 150%;
`;
const ContactFrameRoot = styled.button`
  cursor: pointer;
  border: none;
  border-radius: var(--br-7xs);
  background-color: var(--g100);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-7xs) var(--padding-3xs);
  gap: 0px 6px;
  text-align: left;
  font-size: var(--h3-12-size);
  color: var(--g600);
  font-family: var(--h2-14);
`;

const OfficialsButton: FunctionComponent = () => {
  const navigate = useNavigate();
  return (
    <ContactFrameRoot onClick={()=>navigate("/official")}>
      <VectorFrame>
        <ContactPersonFrame alt="" src="/contact-person-frame.svg" />
      </VectorFrame>
      <Div>병원의 관계자시면 여기를 눌러주세요!</Div>
    </ContactFrameRoot>
  );
};

export default OfficialsButton;
