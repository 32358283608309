import { FunctionComponent } from "react";
import styled from "styled-components";
import CopyButton from "./CopyButton";

const B = styled.b`
  position: relative;
  line-height: 150%;
`;
const Div = styled.div`
  position: relative;
  font-size: var(--h3-12-size);
  line-height: 150%;
  color: var(--g600);
`;
const HospitalLogoFrame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 6px 0px;
`;
const NotoV1hospitalIcon = styled.img`
  height: 48px;
  width: 48px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 48px;
`;
const HospitalLogoFrameParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: var(--gap-xl);
`;
const CertificateTypeText = styled.img`
  height: 9.1px;
  width: 10px;
  position: relative;
`;
const AuthorizationFrame = styled.div`
  height: 17px;
  width: 17px;
  border-radius: var(--br-980xl);
  background-color: var(--key-color01);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-11xs);
  box-sizing: border-box;
`;
const Div1 = styled.div`
  position: relative;
  line-height: 150%;
`;
const VectorContainer = styled.div`
  border-radius: var(--br-7xs);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 0px 6px;
  font-size: var(--h3-12-size);
  color: var(--key-color01);
`;
const B1 = styled.b`
  position: relative;
  line-height: 150%;
  white-space: nowrap;
`;
const Div2 = styled.div`
  position: relative;
  font-size: var(--h2-14-size);
  line-height: 150%;
`;
const MandatoryDocsFrame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px 0px;
`;
const EmailInputText = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  gap: var(--gap-xl);
`;
const FormFrame = styled.div`
  align-self: stretch;
  border-radius: var(--br-xl);
  background-color: var(--g100);
  border: 1px solid var(--g200);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-xl) var(--padding-lgi);
  gap: 30px 0px;
`;
const InnerFrameRoot = styled.div`
  align-self: stretch;
  background-color: var(--g0);
  border-bottom: 2px solid var(--g200);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-3xs) var(--padding-xl) var(--padding-11xl);
  gap: 20px 0px;
  text-align: left;
  font-size: var(--b3-16-size);
  color: var(--g800);
  font-family: var(--h2-14);
`;

const OfficialsFrame: FunctionComponent = () => {
  const hospitalName = localStorage.getItem("hospitalName");
  return (
    <InnerFrameRoot>
      <HospitalLogoFrameParent>
        <HospitalLogoFrame>
          <B>‘{hospitalName}'의 관계자신가요?</B>
          <Div>수정, 추가 내용을 닥터하루에게 보내주세요</Div>
        </HospitalLogoFrame>
        <NotoV1hospitalIcon loading="lazy" alt="" src="/notov1hospital1.svg" />
      </HospitalLogoFrameParent>
      <VectorContainer>
        <AuthorizationFrame>
          <CertificateTypeText
            loading="lazy"
            alt=""
            src="/contact-person-frame.svg"
          />
        </AuthorizationFrame>
        <Div1>병원의 관계자임을 인증하는 서류가 필요해요</Div1>
      </VectorContainer>
      <FormFrame>
        <MandatoryDocsFrame>
          <B1>📑 필수 인증 서류</B1>
          <Div2>: 사업자 등록증</Div2>
        </MandatoryDocsFrame>
        <EmailInputText>
          <MandatoryDocsFrame>
            <B>📧 내용 보내실 곳</B>
            <Div2>: info@choroktech.com</Div2>
          </MandatoryDocsFrame>
          <CopyButton />
        </EmailInputText>
      </FormFrame>
    </InnerFrameRoot>
  );
};

export default OfficialsFrame;
