import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const AppsRedirectPage = () => {
    
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/android/i.test(userAgent )) {
        window.location.href = "https://play.google.com/store/apps/details?id=com.choroktech.client.dr.haru";
    }else if (/iPad|iPhone|iPod/i.test(userAgent)) {
        window.location.href = "https://apps.apple.com/kr/app/id6443821892";
    }
    else alert('유효하지 않은 접근입니다.')
    return null
}

export default AppsRedirectPage;