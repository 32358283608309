import { FunctionComponent, useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import styled from "styled-components";
import HospitalInfoHeader from "../components/AddHospitalInfo/HospitalInfoHeader";
import ImageConfirmation from "../components/AddHospitalInfo/ImageConfirmation";
import SubmitButton from "../components/AddHospitalInfo/SubmitButton";
import HospitalInfoFrame from "../components/AddHospitalInfo/HoapitalInfoFrame";
import SubmitFinishedDialog from "../components/SubmitFinishedDialog/SubmitFinishedDialog";

const B = styled.b`
  flex: 1;
  position: relative;
  line-height: 150%;
  display: inline-block;
  max-width: 100%;
`;
const AppBar = styled.header`
  align-self: stretch;
  background-color: var(--g0);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-lg) var(--padding-xl);
  box-sizing: border-box;
  top: 0;
  z-index: 99;
  position: sticky;
  white-space: nowrap;
  max-width: 100%;
  text-align: center;
  font-size: var(--b3-16-size);
  color: var(--g800);
  font-family: var(--h2-14);
`;
const FrameD = styled.section`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px 0px;
`;
const FrameA = styled.main`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
`;
const DeleteButton = styled.footer`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px var(--padding-xl);
  box-sizing: border-box;
  max-width: 100%;
`;
const DivRoot = styled.div`
  width: 100%;
  min-height: 100vh;
  position: relative;
  background-color: var(--g100);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0px 0px var(--padding-xl);
  box-sizing: border-box;
  gap: 66px 0px;
  letter-spacing: normal;
`;



const AddHospitalInfoPage: FunctionComponent = () => {
  const {infoType} = useParams();
  const [image, setImage] = useState<File | null>(null);
  const [isModalShowed, setIsModalShowed] = useState(false);
  const location = useLocation();

  return (
    <DivRoot>
      <FrameA>
        <AppBar>
          <B>병원 내용 추가</B>
        </AppBar>
        <FrameD>
          <HospitalInfoHeader />
          {infoType !== undefined ? <ImageConfirmation callback = {setImage} /> : <HospitalInfoFrame />}
        </FrameD>
      </FrameA>
      <DeleteButton>
        {
          location.pathname === "/info" ? null :
          image === null || location.pathname ==="/info" ? <SubmitButton image={image} infoType={infoType} disabled={true}/>
          : <SubmitButton image={image} infoType={infoType} disabled={false}/>
        }
      </DeleteButton>
    </DivRoot>
  );
};

export default AddHospitalInfoPage;
