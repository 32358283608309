import { FunctionComponent } from "react";
import styled from "styled-components";
import OfficialsFrame from "../components/Official/OfficialsFrame";
import OfficialsIServiceIntroductionF from "../components/Official/OfficialsIServiceIntroductionF";

const B = styled.b`
  flex: 1;
  position: relative;
  line-height: 150%;
  display: inline-block;
  white-space: nowrap;
  max-width: 100%;
`;
const DoctorInfoFrame = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-height: 40px;
  max-width: 100%;
`;
const AppBar = styled.header`
  align-self: stretch;
  background-color: var(--g0);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs) var(--padding-xl);
  box-sizing: border-box;
  top: 0;
  z-index: 99;
  position: sticky;
  max-width: 100%;
  text-align: center;
  font-size: var(--b3-16-size);
  color: var(--g800);
  font-family: var(--h2-14);
`;
const MobileHospitalFrame = styled.section`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px 0px;
`;
const AppbarContainer = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
`;
const DivRoot = styled.div`
  width: 100%;
  min-height: 100vh;
  position: relative;
  // background-color: var(--g100);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 0px 210px;
  box-sizing: border-box;
  letter-spacing: normal;
`;

const OfficialPage: FunctionComponent = () => {
  return (
    <DivRoot>
      <AppbarContainer>
        <AppBar>
          <DoctorInfoFrame>
            <B>병원 내용 추가</B>
          </DoctorInfoFrame>
        </AppBar>
        <MobileHospitalFrame>
          <OfficialsFrame />
          {/* <OfficialsIServiceIntroductionF /> */}
        </MobileHospitalFrame>
      </AppbarContainer>
    </DivRoot>
  );
};

export default OfficialPage;
