import { FunctionComponent, useContext } from "react";
import styled from "styled-components";
import OfficialsButton from "./OfficialsButton";

const P = styled.p`
  margin: 0;
`;
const B = styled.b`
  flex: 1;
  position: relative;
  line-height: 150%;
  display: inline-block;
  min-width: 166px;
`;
const NotoV1hospitalIcon = styled.img`
  height: 48px;
  width: 48px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 48px;
`;
const TextA = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 0px 10px;
`;
const Div = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--h3-12-size);
  line-height: 150%;
  color: var(--g400);
`;
const FrameE = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px 0px;
`;
const ContactButtonIcon = styled.img`
  height: 9.1px;
  width: 10px;
  position: relative;
`;
const Vector = styled.div`
  height: 17px;
  width: 17px;
  border-radius: var(--br-980xl);
  background-color: var(--key-color01);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-11xs);
  box-sizing: border-box;
`;
const Div1 = styled.div`
  position: relative;
  line-height: 150%;
`;
const FrameF = styled.div`
  border-radius: var(--br-7xs);
  background-color: var(--g100);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-7xs) var(--padding-3xs);
  gap: 0px 6px;
  font-size: var(--h3-12-size);
  color: var(--g600);
`;
const AppbarRoot = styled.div`
  align-self: stretch;
  background-color: var(--g0);
  border-bottom: 2px solid var(--g200);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-3xs) var(--padding-xl) var(--padding-xl);
  gap: 20px 0px;
  text-align: left;
  font-size: var(--b3-16-size);
  color: var(--g800);
  font-family: var(--h2-14);
`;

const HospitalInfoHeader: FunctionComponent = () => {

  const hospitalName = localStorage.getItem('hospitalName');
  return (
    <AppbarRoot>
      <FrameE>
        <TextA>
          <B>
            <P>'{hospitalName}'의 정보를</P>
            <P>사진으로 간단하게 제보해 주세요.</P>
          </B>
          <NotoV1hospitalIcon
            loading="lazy"
            alt=""
            src="/notov1hospital1.svg"
          />
        </TextA>
        <Div>병원 정보를 알려주시면 덕분에 모두에게 도움이 되어요</Div>
      </FrameE>
      <OfficialsButton />
    </AppbarRoot>
  );
};

export default HospitalInfoHeader;
