import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export type HospitalInfoButtonType = {
  frameName?: string;
  type?: string;
};

const MingcutedownFillIcon = styled.img`
  height: 12px;
  width: 12px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
`;
const Div = styled.div`
  position: relative;
  line-height: 150%;
`;
const MingcutedownfillFramesRoot = styled.div`
  cursor: pointer;
  align-self: stretch;
  background-color: var(--g0);
  border-bottom: 1px solid var(--g200);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-sm) var(--padding-xl);
  gap: 0px 10px;
  text-align: left;
  font-size: var(--h2-14-size);
  color: var(--g800);
  font-family: var(--h2-14);
`;

const HospitalInfoButton: FunctionComponent<HospitalInfoButtonType> = ({
  frameName, type
}) => {
  const navigate = useNavigate();
  return (
    <MingcutedownfillFramesRoot onClick={()=>{navigate(`/info/${type}`)}}>
      <MingcutedownFillIcon alt="" src="/mingcutedownfill.svg" />
      <Div>{frameName}</Div>
    </MingcutedownfillFramesRoot>
  );
};

export default HospitalInfoButton;
