import { FunctionComponent, type CSSProperties, MouseEventHandler, useContext, useRef } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { SetStateAction } from "react";


const Btn = styled.b<{ disabled: boolean; }>`
  disabled: ${(p) => p.disabled.toString()};
  // cursor: ${(p) => p.disabled ? "pointer" : "none"};
  flex: 1;
  position: relative;
  font-size: var(--b3-16-size);
  line-height: 150%;
  font-family: var(--h2-14);
  color: var(--g400);
  text-align: center;
  color: ${(p) => p.disabled ? "#9FA4A9" : "#fff"};
`;
const BtnRoot = styled.button<{
  disabled: boolean;
}>`
  disabled: ${(p) => p.disabled.toString()};
  cursor: ${(p) => p.disabled ? "defalut" : "pointer"};
  border: none;
  padding: var(--padding-xs) var(--padding-xl);
  background-color: var(--g200);
  flex: 1;
  border-radius: var(--br-xs);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  white-space: nowrap;
  max-width: 100%;
  background-color: ${(p) => p.disabled ? "#E9EBED" : "#fda2d3"};
`;

interface SubmitButtonType {
  disabled: boolean;
  image: File | null;
  infoType: string | undefined;
  // showModal: (show: boolean) => void;
}

const SubmitButton: FunctionComponent<SubmitButtonType> = ({
  disabled,
  image,
  infoType,
  // showModal
}) => {
  const modalRef = useRef();
  const hospitalId = localStorage.getItem('hospitalId');
  const userId = localStorage.getItem('userId');
  const formData = new FormData();
  const navigate = useNavigate();
  const handleSubmit: MouseEventHandler = async (event) => {
    event.preventDefault();

    if (!image) {
      return;
    }

    try{
      formData.append('file', image);

      const response = await fetch(`/hospital/infoR?type=${infoType}&hospitalId=${hospitalId}&userId=${userId}`, {
        method: 'POST',
        body: formData,
      });

      console.log(response)

      
      // showModal(true);

      // modalRef.current?.showModal();
      alert("정보가 제출되었어요")
      navigate('/info');

    }catch(_){
      console.error(_);
      alert("잠시 후 다시 시도해주세요.")
    }
  };

  return (
    <BtnRoot disabled={disabled} onClick={handleSubmit}>
      <Btn disabled={disabled}>정보 수정 제출</Btn>
    </BtnRoot>
  );
};

export default SubmitButton;
