import { FunctionComponent } from "react";
import styled from "styled-components";
import CompanyLogo from "../components/Icon/CompanyLogo";

const H = styled.h3`
  margin: 0;
  width: 201px;
  position: relative;
  font-size: inherit;
  line-height: 150%;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  white-space: nowrap;
  box-sizing: border-box;
  padding-right: var(--padding-xl);
`;
const ParentAgreement = styled.header`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xl) var(--padding-lgi);
  text-align: center;
  font-size: var(--b2-20-size);
  color: var(--g800);
  font-family: var(--h3-12);
`;
const P = styled.p`
  margin: 0;
`;
const P1 = styled.p`
  margin: 0;
  white-space: pre-wrap;
`;
const A = styled.a`
  color: inherit;
`;
const PcContainer = styled.div`
//   height: 2646px;
  flex: 1;
  position: relative;
  line-height: 150%;
  display: inline-block;
  max-width: 100%;
`;
const ParentAgreement1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs) var(--padding-xl);
  box-sizing: border-box;
  max-width: 100%;
`;
const DoctorHourServiceAgreement = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  text-align: left;
  font-size: var(--b4-14-size);
  color: var(--g800);
  font-family: var(--h3-12);
`;
const DoctorHourServiceAgreementParent = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 80px 0px;
  max-width: 100%;
  @media screen and (max-width: 975px) {
    gap: 40px 0px;
  }
  @media screen and (max-width: 700px) {
    gap: 20px 0px;
  }
`;
const DivRoot = styled.div`
  width: 100%;
  background-color: var(--g0);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 0px var(--padding-21xl);
  box-sizing: border-box;
  letter-spacing: normal;
`;

const PrecautionsPage: FunctionComponent = () => {
  return (
    <DivRoot>
      <DoctorHourServiceAgreementParent>
        <DoctorHourServiceAgreement>
          <ParentAgreement>
            <H>닥터하루 서비스 이용약관</H>
          </ParentAgreement>
          <ParentAgreement1>
            <PcContainer>
              <P>
                본 닥터하루 이용약관(이하 ‘본 약관’이라 함)은 주식회사
                초록테크의 닥터하루 (이하 "본사"’라 함)가 본 서비스를 이용하는
                고객(이하 ‘고객’이라 함)에게 제공하는 모든 제품 및 서비스(이하
                ‘본 서비스’라 함)의 이용에 관한 조건에 대해 정합니다.
              </P>
              <P>​</P>
              <P>1. 정의</P>
              <P>본 약관에서 사용하는 용어의 정의는 다음과 같습니다.</P>
              <P>​</P>
              <P>
                1.1.‘콘텐츠’란 문장, 음성, 이미지, 동영상, 소프트웨어, 프로그램,
                코드 및 기타 정보를 말합니다.
              </P>
              <P>
                1.2.‘본 콘텐츠’란 본 서비스를 통해 접속할 수 있는 콘텐츠를
                말합니다.
              </P>
              <P>
                1.3.‘투고 콘텐츠’란 고객이 본 서비스에 투고, 송신 또는 업로드한
                콘텐츠를 말합니다.​
              </P>
              <P>
                1.4.'포인트’란 본 서비스가 유상으로 제공하는 서비스 또는
                콘텐츠와 교환 가능한 선불 결제 수단 등을 말합니다.
              </P>
              <P>
                1.5.‘개별 이용약관’이란 본 서비스에 관하여 본 약관과는 별도로
                ‘약관’, 가이드라인’, ‘정책’ 등의 명칭으로 주식회사 초록테크
                닥터하루 서비스가 배포 또는 게시한 이용약관을 말합니다.
              </P>
              <P>​</P>
              <P>2. 약관 동의</P>
              <P>
                2.1고객은 본 약관에 따라 본 서비스를 이용해야 합니다. 고객은 본
                약관에 대해 동의를 했을 경우에 한하여 본 서비스를 이용할 수
                있습니다.
              </P>
              <P>
                2.2.고객이 미성년자일 경우에는 친권자 등 법정대리인의 사전
                동의를 얻은 후 본 서비스를 이용하십시오. 또한 고객이 본 서비스를
                사업자를 위해 이용할 경우에는 당해 사업자 역시 본 약관에 동의한
                후, 본 서비스를 이용하십시오.
              </P>
              <P>
                2.3.본 서비스에 적용되는 개별 이용약관이 존재할 경우, 고객은 본
                약관 외에 개별 이용약관의 규정에 따라 본 서비스를 이용해야
                합니다.
              </P>
              <P>​</P>
              <P>3. 약관 변경</P>
              <P1>
                (주)초록테크가 필요하다고 판단할 경우, 본사는 본 서비스 목적의
                범위 내에서 본 약관을 변경할 수 있습니다. 이때 본사는 본 약관의
                변경본의 내용과 변경의 효력일을 본 서비스 또는 본사의 웹사이트에
                게시하거나, 이를 본사가 정한 방법으로 고객에게 통지하여야
                합니다.
              </P1>
              <P>본 약관의 변경본은 그 효력일로부터 효력이 발생합니다.</P>
              <P>​</P>
              <P>4. 계정</P>
              <P>
                4.1.본 서비스 이용을 위해 고객은 특정 정보를 등록하여 계정을
                생성해야 할 수 있습니다. 고객은 진실하고 정확하며 완전한 정보를
                등록해야 하며 언제나 최신 정보가 적용되도록 수정해야 합니다.
              </P>
              <P>
                4.2.고객이 본 서비스 이용을 위해 인증 정보를 등록할 경우, 이를
                부정하게 이용당하지 않도록 본인 책임 하에 엄중하게 관리해야
                합니다. 본사는 등록된 인증 정보를 이용하여 이루어진 일체의
                행위를 인증 정보를 등록한 고객 본인의 행위로 간주할 수 있습니다.
              </P>
              <P>
                4.3.본 서비스에 등록한 고객은 언제라도 계정을 삭제하고 탈퇴할 수
                있습니다.
              </P>
              <P1>
                4.4.본사는 마지막 접속 시점부터 １년 이상 경과한 계정을 해당
                고객에 대한 사전 통지 없이 삭제할 수 있습니다.
              </P1>
              <P>
                4.5.고객이 본 서비스에서 가지는 모든 이용 권한은 이유를 막론하고
                계정이 삭제된 시점에 소멸됩니다. 고객의 실수로 계정을 삭제한
                경우에도 계정을 복구할 수 없음에 유의하시기 바랍니다.
              </P>
              <P>
                4.6.본 서비스의 계정은 고객에게 일신전속적으로 귀속됩니다.
                고객이 본 서비스에서 가지는 모든 이용권은 제삼자에게 양도, 대여
                또는 처분할 수 없으며, 제삼자에게 상속 또는 승계될 수 없습니다.
              </P>
              <P>​</P>
              <P>5. 프라이버시</P>
              <P>5.1.본사는 고객의 프라이버시를 존중합니다.</P>
              <P1>
                {`5.2.본사는 고객의 프라이버시 정보와 개인정보를 `}
                <A
                  href="http://terms.line.me/line_rules/?lang=ko"
                  target="_blank"
                >
                  {" "}
                  개인정보 취급방침
                </A>
                에 따라 적절하게 취급합니다.
              </P1>
              <P>
                5.3.본사는 고객으로부터 수집한 모든 정보를 안전하게 관리하기
                위해 보안에 최대한 주의를 기울이고 있습니다.
              </P>
              <P>​</P>
              <P>6. 서비스 제공</P>
              <P>
                6.1.고객은 본 서비스를 이용하는 데 있어 필요한 PC나 휴대전화
                단말기, 스마트폰 및 기타 통신 기기, 운영 체제, 통신 수단 및 전력
                등을 고객의 비용과 책임 하에 준비해야 합니다.
              </P>
              <P>
                6.2.본사는 본 서비스의 전부 또는 일부를 고객의 연령 및 본인 확인
                여부, 등록정보 유무 등 본사가 필요하다고 판단한 조건을
                만족시키는 고객에 한하여 제공할 수 있습니다.
              </P>
              <P>
                6.3.본사는 필요하다고 판단될 경우, 고객에 대한 사전 통지 없이
                언제라도 본 서비스의 전부 또는 일부 내용을 변경할 수 있습니다.
              </P>
              <P1>
                6.4 본사는 다음 각 호의 경우 고객에 대한 사전 통지 없이 언제라도
                본 서비스의 전부 또는 일부의 제공을 중지할 수 있습니다.
              </P1>
              <P>(1)시스템을 유지보수 또는 수리하는 경우</P>
              <P>
                (2)사고(화재, 정전 등), 천재지변, 전쟁, 폭동, 노동 쟁의 등
                불가항력으로 인해 본 서비스를 제공할 수 없는 경우
              </P>
              <P>
                (3)시스템이 고장 나거나 심각한 부하가 걸리는 경우(4) 고객 또는
                제삼자의 안전을 확보하거나 공공복리를 위한 비상사태의 경우
              </P>
              <P1>
                (5)(1) 부터 (4)까지 명시된 사항 이외에 본사가 필요하다고
                합리적으로 판단하는 경우
              </P1>
              <P>​</P>
              <P>7. 긴급 신고 기능 미적용</P>
              <P>
                본 서비스는 경찰기관, 해상보안기관, 소방기관 기타 기관에 대한
                긴급 신고 수단을 제공하지 않습니다.
              </P>
              <P>​</P>
              <P>8. 광고 게재</P>
              <P>
                본사는 본 서비스에 본사 또는 제삼자의 광고를 게재할 수 있습니다.
              </P>
              <P>​</P>
              <P>9. 제휴 서비스</P>
              <P>
                본 서비스는 본사 이외의 제삼자가 제공하는 서비스 또는 콘텐츠를
                포함할 수 있습니다. 이러한 서비스 및/또는 콘텐츠에 대한 모든
                책임은 이를 제공하는 제삼자에게 있습니다. 또한, 이러한 서비스 및
                콘텐츠에는 이를 제공하는 제삼자가 정한 이용약관 기타 조건이
                적용되는 경우가 있습니다.
              </P>
              <P>​</P>
              <P>10. 콘텐츠</P>
              <P1>
                10.1.본사는 본사가 제공하는 본 콘텐츠에 대해 양도 및 재실시가
                불가능하고 비독점적이며 본 서비스의 이용을 유일한 목적으로 하는
                이용권을 고객에게 부여합니다.
              </P1>
              <P>
                10.2.고객은 추가 이용료, 이용 기간 등의 이용 조건이 별도로
                규정된 본 콘텐츠를 이용할 경우, 이와 같은 이용 조건에 따라야
                합니다. 본 서비스의 화면에 ‘구입’, ‘판매’ 등이 표기되어 있을
                경우에도 본사가 고객에게 제공하는 본 콘텐츠에 관한 지식재산권
                기타 권리는 본사가 보유하고 고객에게 이전되지 않으며, 고객에게는
                상기 이용권만이 부여됩니다.
              </P>
              <P>
                10.3.고객은 본 콘텐츠를 본 서비스에서 미리 정한 이용 형태를
                넘어서 이용(복제, 송신, 전재, 수정 등의 행위를 포함하나 이에
                한정되지 아니함)해서는 안 됩니다.
              </P>
              <P>
                10.4.투고 콘텐츠의 전부 또는 일부의 백업은 고객이 직접 실시해야
                합니다. 본사는 투고 콘텐츠의 백업을 실시할 의무를 갖지 않습니다.
              </P>
              <P>
                10.5.본 서비스는 여러 고객이 콘텐츠를 편집(투고, 수정 및 삭제
                등)할 수 있는 기능을 포함하는 경우가 있습니다. 이때 고객은
                본인의 투고 콘텐츠에 대한 다른 고객의 편집을 허락하는 것으로
                합니다.
              </P>
              <P1>
                10.6.고객은 투고 콘텐츠에 대해 보유하는 권리를 기존과 다름없이
                보유하며, 본사가 이 권리를 취득하지는 않습니다. 단, 투고 콘텐츠
                중 다른 고객(즉, 고객의 ‘친구’뿐만 아니라)에게 일반적으로
                공개되는 것에 한하여 고객은 해당 콘텐츠를 서비스 및/또는
                프로모션에 이용할 수 있는 권리(본사가 필요하고 적절하다고 판단한
                범위 내에서 생략 등의 변경을 가할 수 있는 권리 및 이러한 이용
                권한을 본사가 제휴하는 제삼자에게 재실시할 수 있는 권리를
                포함함)를 본사에 부여하는 것으로 간주합니다.
              </P1>
              <P>
                10.7.고객은 본사 또는 제삼자가 상기 제10.5조 및 제10.6조에 따라
                투고 콘텐츠를 사용하는 것과 관련하여 저작인격권과 같은 권리를
                행사해서는 안됩니다.
              </P>
              <P>
                10.8.본사는 관련 법령 또는 본 약관의 준수 상황 등을 확인할
                필요가 있을 경우, 법령이 허락하는 한도 내에서 고객의 투고
                콘텐츠의 내용을 확인할 수 있습니다. 단, 본사가 그러한 확인
                작업을 실시할 의무를 가지는 것은 아닙니다.
              </P>
              <P>
                10.9.본사가 고객이 투고 콘텐츠에 관하여 관련 법령 또는 본 약관을
                위반하거나 또는 위반할 우려가 있다고 판단한 경우 또는 기타
                본사의 업무상 필요한 경우, 고객에게 사전 통지 없이 투고 콘텐츠를
                삭제하는 등의 방법을 통하여 본 서비스에서 투고 콘텐츠를 이용하는
                것을 제한할 수 있습니다.
              </P>
              <P>​</P>
              <P>11. 포인트</P>
              <P>
                11.1.포인트는 본 서비스에서 구입하거나 혹은 캠페인, 기타 본사가
                지정한 방법을 통해 고객에게 부여됩니다. 포인트의 구입 단위, 결제
                방법 및 기타 포인트의 부여 조건은 본사가 정하여 본 서비스 또는
                본사의 웹사이트에 게재합니다.
              </P>
              <P1>
                11.2.포인트는 본사가 지정한 서비스 및 콘텐츠 이외의 현금, 재물
                기타 경제적 이익과 교환 또는 사용할 수 없습니다. 서비스 또는
                콘텐츠 교환에 필요한 포인트수 및 기타 포인트의 교환 조건은
                본사가 정하여 본 서비스 또는 본사의 웹사이트에 게재합니다.
              </P1>
              <P1>
                11.3.포인트는 이를 취득한 계정에서만 이용 가능합니다. 또한 운영
                체제가 다른 단말기에서 제공되는 포인트는 동일한 명칭이더라도
                종류가 다르므로 이관되거나 합산될 수 없습니다.
              </P1>
              <P>
                11.4.포인트는 관련 법령이 정하는 바에 따라 환불될 수 있습니다.
                이때 본사는 관련 법령에 따라 환불 절차를 정하여야 하며, 본사의
                웹사이트 등에서 이러한 정보를 이용할 수 있도록 하여야 합니다.
              </P>
              <P>​</P>
              <P>12. 구독 서비스 제공</P>
              <P>
                12.1.고객은 일정 기간 동안 본사에서 지정한 특정 본 콘텐츠를
                이용하기 위하여 일정 금액을 지불하고(이하 ‘구독 서비스’라 함),
                요금 지불의 대가로서 또는 본사가 정한 약관에 따라 서비스를 받을
                수 있습니다. 고객은 본 약관, 구독 서비스 요금, 결제 방법 및 기타
                본 서비스 또는 본사의 웹사이트에 게재된 이용약관을 준수하여야
                합니다.
              </P>
              <P>
                12.2.고객은 언제든지 구독 서비스의 해지를 위한 절차를 밟을 수
                있습니다. 단, 고객이 예정된 이용 기간 전에 해지 절차를 밟더라도
                해당 이용 기간을 변경하거나 구독 서비스의 구매를 취소할 수는
                없습니다. 이때 이미 지불한 요금은 환급되지 않으며, 비례배분
                방식으로 환급되지도 않습니다. 단, 법령에서 달리 규정하는
                경우에는 전술한 사항이 적용되지 않습니다.
              </P>
              <P>
                12.3.고객이 지정된 날짜 및 시간까지 해지 절차를 완료하지 않을
                경우, 해당 구독 서비스의 이용 기간이 종료된 후에도 본사가 정한
                조건에 따라 구독 서비스 이용 기간이 자동으로 갱신될 수 있습니다.
              </P>
              <P>​</P>
              <P>13. 금지 사항</P>
              <P>
                고객은 본 서비스 이용 중 다음에 기재된 행위를 해서는 안 됩니다.
              </P>
              <P>
                13.1.법령, 법원의 판결, 결정 혹은 명령 또는 법령상 구속력을
                가지는 행정 조치에 위반되는 행위.
              </P>
              <P>13.2.공공질서 또는 미풍양속을 저해할 우려가 있는 행위.</P>
              <P>
                13.3.본사 및/또는 제삼자의 저작권, 상표권, 특허권 등의
                지식재산권, 명예권, 프라이버시권 및 기타 법령상 또는 계약상의
                권리를 침해하는 행위.
              </P>
              <P>
                13.4.과도하게 폭력적인 표현, 노골적인 성적 표현, 아동 포르노나
                아동 학대에 해당하는 표현, 인종, 국적, 신조, 성별, 사회적 지위와
                출신 가족 등에 관하여 차별하는 표현, 자살, 자상 행위, 약물
                남용을 유인 또는 조장하는 표현 또는 기타 반사회적인 내용을
                포함하고 타인에게 불쾌감을 주는 표현을 투고 또는 송신하는 행위.
              </P>
              <P>
                13.5.본사 또는 제삼자를 사칭하는 행위 또는 의도적으로 허위
                정보를 유포하는 행위.
              </P>
              <P1>
                13.6.동일하거나 유사한 메시지를 불특정 다수의 고객에게 송신하는
                행위(본사가 인정한 것은 제외함), 다른 고객을 무차별적으로 친구
                또는 그룹 대화에 추가하는 행위, 기타 본사가 스팸으로 판단한
                행위.
              </P1>
              <P>
                13.7.본사가 정한 방법 외의 방법으로 본 서비스 또는 본 콘텐츠의
                이용권을 현금, 재물 기타 경제적 이익과 교환하는 행위.
              </P>
              <P>
                13.8.영업, 선전, 광고, 권유, 기타 영리를 목적으로 본 서비스를
                이용하는 행위(본사가 인정한 것은 제외함), 성적 행위와 외설적인
                행위를 목적으로 본 서비스를 이용하는 행위, 면식이 없는
                제삼자와의 성적인 만남과 교제를 목적으로 본 서비스를 이용하는
                행위, 다른 고객에 대한 악의적인 행위나 비방 및 중상모략 등을
                목적으로 본 서비스를 이용하는 행위 또는 기타 본 서비스가 미리
                정한 이용 목적과 다른 목적으로 본 서비스를 이용하는 행위.
              </P>
              <P>13.9.반사회적인 세력에 대한 이익 공여와 기타 협력 행위.</P>
              <P>13.10.종교 활동 또는 특정 종교 단체를 권유하는 행위.</P>
              <P>
                13.11.타인의 개인정보, 등록 정보, 사용자 이력 등을 불법으로
                수집, 공개 또는 제공하는 행위.
              </P>
              <P>
                13.12.본 서비스의 서버 및/또는 네트워크 시스템에 지장을 주는
                행위, 봇(bot), 치팅 툴 또는 기타 기술적인 수단을 이용하여 본
                서비스를 불법으로 조작하는 행위, 본 서비스의 장애를 의도적으로
                이용하는 행위, 동일한 질문을 필요 이상으로 반복하는 등 본사에
                대해 부당한 문의 및/또는 요구를 하는 행위 및 기타 본사의 본
                서비스 운영 또는 다른 고객의 본 서비스 이용을 방해하거나 이에
                지장을 주는 행위.
              </P>
              <P>
                13.13.상기13.1.부터 13.12까지의 어느 하나에 해당되는 행위를 원조
                또는 조장하는 행위.
              </P>
              <P>13.14.기타 부적당하다고 본사가 합리적으로 판단한 행위.</P>
              <P>​</P>
              <P>14. 고객의 책임</P>
              <P>
                14.1.고객은 고객 자신의 책임 하에 본 서비스를 이용해야 하며, 본
                서비스의 이용에 따라 이루어진 모든 행위 및 그 결과에 대해서
                일체의 책임을 져야 합니다.
              </P>
              <P1>
                14.2.본사는 해당 고객이 다음 각 호의 어느 하나에 속하거나 속할
                가능성이 있는 경우, 해당 고객에 대한 사전 통지 없이 본 서비스의
                전부 또는 일부의 사용을 중지하거나, 계정을 정지 또는 삭제하거나,
                본 서비스와 관련한 본사와 고객 간의 모든 계약(본 약관에 근거한
                계약을 포함하나 이에 한정되지 아니함)을 해지하거나, 본사가
                필요하며 적절하다고 합리적으로 판단한 기타 조치를 취할 수
                있습니다.
              </P1>
              <P>
                (1)고객이 관련 법령, 본 약관 또는 개별 이용약관을 위반하는 경우
              </P>
              <P>(2)고객이 반사회적 단체의 구성원이거나 그와 관련된 경우</P>
              <P>
                (3)고객이 허위 정보를 유포하거나, 부정한 방법 기타 불법적인
                수단을 사용하여 본사의 신용을 훼손하는 경우
              </P>
              <P>
                (4)고객에 대하여 압류, 가압류 또는 경매가 신청되는 경우, 파산,
                회생 또는 유사한 절차가 개시되는 경우 또는 기타 본사가 고객의
                신용에 대하여 불확실성이 있다고 합리적으로 판단하는 경우
              </P>
              <P>
                (5)(1)부터 (4)까지 명시된 것 이외의 사유로 인하여 고객과의 신뢰
                관계가 상실되거나 기타 본사가 본 서비스를 고객에게 제공하는 것이
                적절하지 않다고 합리적으로 판단하는 경우
              </P>
              <P>
                14.3. 고객의 본 서비스 이용에 기인하여(본사가 이러한 이용으로
                인해 제삼자로부터 클레임을 받은 경우를 포함하나 이에 한정되지
                아니함) 본사가 직접적 혹은 간접적으로 어떤 손해(변호사 비용
                부담을 포함하나 이에 한정되지 아니함)를 입었을 경우, 고객은
                본사의 요구에 따라 즉시 이를 보상해야 합니다.
              </P>
              <P>​</P>
              <P>15. 보증의 미제공</P>
              <P1>
                본사는 본 서비스(본 콘텐츠를 포함함)와 관련하여 하자(보안 등에
                관련된 결함, 오류와 버그, 권리 침해 등을 포함하나 이에 한정되지
                아니함)가 없다는 점 및 안전성, 신뢰성, 정확성, 완전성, 유효성 및
                특정 목적과의 적합성을 명시적 또는 암묵적으로 보증하지 않습니다.
                본사는 고객에 대해 이러한 하자를 제거하고 본 서비스를 제공할
                의무를 갖지 않습니다.
              </P1>
              <P>​</P>
              <P>16.본사의 책임 제한</P>
              <P1>
                16.1.본사는 해당 손해가 본사의 고의 또는 중과실에 의하여 발생한
                경우를 제외하고 본 서비스로 인하여 고객에게 발생한 모든 손해에
                대해 일체의 책임을 지지 않습니다. 단, 본 서비스의 이용에 관한
                본사와 고객 간의 계약이 소비자계약법에서 규정하는
                소비자계약(이하 ‘소비자계약’이라 함)에 해당될 경우, 본사는
                본사의 과실(중과실을 제외함)에 의한 채무불이행 또는 불법행위로
                인해 고객에게 발생한 손해에 대하여 (A) 통상손해(즉, 특별손해를
                제외함) 및 (B) 해당 손해가 발생한 달에 고객으로부터 수령한 본
                서비스 이용료를 상한 금액으로 한 범위 내에서 책임을 지게 됩니다.
              </P1>
              <P>
                16.2.만일 고객에게 발행한 손해가 본사의 중과실로 인해 발생한
                경우, 본사는 (A) 통상손해(즉, 수익의 상실 기타 특별손해를
                제외함) 및 (B) 해당 손해가 발생한 달에 유료 서비스에 대해
                고객으로부터 수령한 본 서비스 이용료를 상한 금액으로 한 범위
                내에서 손해를 배상하여야 합니다. 단, 전술한 사항은 본사와 해당
                고객 간의 계약이 소비자계약에 해당하는 경우에 적용되지
                아니합니다.
              </P>
              <P>​</P>
              <P>17.본 약관과 관련 법령의 관계</P>
              <P>
                본 약관의 조항이 본 서비스와 관련하여 본사와 고객 간의 계약에
                적용되는 법령을 위반하는 경우(소비자계약법을 포함하나 이에
                한정되지 아니함), 해당 조항 중 법령을 위반하는 부분은 고객과의
                계약에 적용되지 아니합니다. 단, 본 약관의 나머지 부분은 영향을
                받지 아니합니다.
              </P>
              <P>​</P>
              <P>18. 연락 방법</P>
              <P>
                18.1.본 서비스와 관련해 본사가 고객에게 연락을 할 때는 본 서비스
                또는 본사가 운영하는 웹사이트 내의 적당한 장소에 게시하거나 기타
                본사가 적당하다고 판단하는 방법으로 합니다.
              </P>
              <P>
                18.2.본 서비스와 관련해 고객이 본사에 연락을 할 때는 본 서비스
                또는 본사가 운영하는 웹사이트 내의 고객문의 페이지를 이용하거나
                본사가 지정하는 방법으로 합니다.
              </P>
              <P>​</P>
              <P>19.준거법과 재판 관할</P>
              <P1>
                본 약관은 한국어를 정본으로 하며, 국내법을 준거법으로 합니다. 본
                서비스에 기인 또는 관련하여 고객과 본사간에 발생한 분쟁에
                대해서는 서울중앙지방법원을 제1심 전속적 합의 관할 법원으로
                합니다.
              </P1>
            </PcContainer>
          </ParentAgreement1>
        </DoctorHourServiceAgreement>
        <CompanyLogo />
      </DoctorHourServiceAgreementParent>
    </DivRoot>
  );
};

export default PrecautionsPage;
