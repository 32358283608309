import { FunctionComponent } from "react";
import styled from "styled-components";
import HospitalInfoButton from "./HospitalInfoButton";

const B = styled.b`
  width: 277px;
  position: relative;
  line-height: 150%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;
const InnerFrames = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-xl);
`;
const HospitalInfoAdd = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const TimeAndPhoneFrameRoot = styled.div`
  align-self: stretch;
  background-color: var(--g0);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--h2-14-size);
  color: var(--g800);
  font-family: var(--h2-14);
`;

const HoapitalInfoFrame: FunctionComponent = () => {
  return (
    <TimeAndPhoneFrameRoot>
      <InnerFrames>
        <B>병원 정보 추가</B>
      </InnerFrames>
      <HospitalInfoAdd>
        <HospitalInfoButton frameName="진료시간" type="time"/>
        <HospitalInfoButton frameName="전화번호" type="phoneNumber"/>
        <HospitalInfoButton frameName="위치/이전" type="location"/>
        <HospitalInfoButton frameName="병원 정보 추가" type="etc"/>
      </HospitalInfoAdd>
    </TimeAndPhoneFrameRoot>
  );
};

export default HoapitalInfoFrame;
