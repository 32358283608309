import { useContext, useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
  Navigate
} from "react-router-dom";
import AddHospitalInfoPage from "./pages/AddHospitalInfoPage";
import OfficialPage from "./pages/OfficialPage";
import RedirectPage from "./pages/RedirectPage";
import SubmitFinishedDialog from "./components/SubmitFinishedDialog/SubmitFinishedDialog";
import PrecautionsPage from "./pages/Precautions";
import AppsRedirectPage from "./pages/AppsRedirectPage";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/apps":
        title = "";
        break;
      case "/":
        title = "";
        break;
      case "/info":
        title = "";
        metaDescription = "";
        break;
      case "/info/:infoType":
        title = "";
        metaDescription = "";
        break;
      case "/official":
        title = "";
        metaDescription = "";
        break;
      case "/precautions":
        title = "유의사항";
        metaDescription = "";
        break;
      default:
        title = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag: HTMLMetaElement | null = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
    
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<RedirectPage />} />
      <Route path="/apps" element={<AppsRedirectPage />} />
      <Route path="/info" element={<AddHospitalInfoPage />} />
      {/* <Route path="/info" element={<SubmitFinishedDialog />} /> */}
      <Route path="/info/:infoType" element={<AddHospitalInfoPage />} />
      <Route path="/official" element={<OfficialPage />} />
      <Route path="/precautions" element={<PrecautionsPage />} />
    </Routes>
  );
}
export default App;
