import { FunctionComponent, MouseEventHandler} from "react";
import styled from "styled-components";

const Delete21pxIconRoot = styled.img`
  height: 24px;
  width: 24px;
  position: absolute;
  margin: 0 !important;
  top: -6px;
  right: -6px;
`;
interface Props {
  onClick: MouseEventHandler;
}
const CancelButton: FunctionComponent<Props> = ({ onClick }) => {
  return <Delete21pxIconRoot loading="lazy" alt="" src="/delete21px.svg" onClick={onClick} />;
};

export default CancelButton;
