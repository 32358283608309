import { FunctionComponent } from "react";
import CancelButton from "./CancelButton";
import styled from "styled-components";

const CameraRoot = styled.div`
  align-self: stretch;
  height: 335px;
  border-radius: var(--br-xs);
  background-color: var(--g100);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-21xl);
  box-sizing: border-box;
  position: relative;
`;
const ImagePreview = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

interface Props {
  image: File | null;
  setImage: React.Dispatch<React.SetStateAction<File | null>>;
}

const PhotoImage: FunctionComponent<Props> = ({ image, setImage }) => {
  const imageUrl = image ? URL.createObjectURL(image) : undefined;

  const handleCancel = () => {
    setImage(null);
  };

  return (
    <CameraRoot>
      {imageUrl && <ImagePreview src={imageUrl} alt="Preview" />}
      <CancelButton onClick = {handleCancel} />
    </CameraRoot>
  );
};

export default PhotoImage;
